import React, { ChangeEvent } from 'react';

import Inputs from 'components/Inputs';
import Dropdown from 'components/Inputs/Dropdown';
import Typography from 'kingpin/atoms/Typography';
import ShadowedList from 'components/ShadowedList';
import { LabelDiv } from 'screens/Boards/BoardCreationWizard';
import EmptyVis from '../../Sections/EmptyVis';
import WidgetFooter from '../../WidgetFooter';
import { WidgetWrapper } from '../MetricSlideoutWizard';
import TargetProgress from '../../Sections/TargetProgress';

const TargetProgressSlideoutWizard = ({
  saveWidget,
  title,
  onTitleChanged,
  targetFieldOptions,
  isDisabled,
  close,
  sectionDraft,
}: {
  saveWidget: () => void;
  title: string;
  onTitleChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  targetFieldOptions: DropdownOption[];
  isDisabled: boolean;
  close: () => void;
  sectionDraft?: PerformanceBoardTypes.SlideOut.TargetProgressSection;
}) => {
  return (
    <>
      <ShadowedList>
        <WidgetWrapper>
          <div style={{ marginBottom: 24 }}>
            <LabelDiv>
              <Typography.Body type="Label">Widget Title</Typography.Body>
            </LabelDiv>
            <Inputs.TextInput
              value={title}
              placeholder="Enter Title"
              onChange={onTitleChanged}
              maxLength={40}
              autoFocus
              data-testid={'title'}
            />
          </div>
          <div style={{ width: '100%', marginBottom: 16 }}>
            <LabelDiv>
              <Typography.Body type="Label">
                Select Targeted Fields
              </Typography.Body>
            </LabelDiv>
            <Dropdown
              options={targetFieldOptions}
              placeholder={'Select Target Fields'}
              isDisabled={targetFieldOptions.length === 0}
              selectedLabel={
                targetFieldOptions.filter((f) => f.isSelected).length === 0
                  ? undefined
                  : targetFieldOptions
                      .filter((f) => f.isSelected)
                      .map((o) => o.label)
                      .join(', ')
              }
            />
            {targetFieldOptions.length === 0 && (
              <Typography.Body type="Body 12">No Target Fields</Typography.Body>
            )}
          </div>
          {isDisabled || !sectionDraft ? (
            <EmptyVis />
          ) : (
            <>
              <LabelDiv>
                <Typography.Body type="Label">Preview</Typography.Body>
              </LabelDiv>
              <TargetProgress section={sectionDraft} />
            </>
          )}
        </WidgetWrapper>
      </ShadowedList>

      <WidgetFooter
        saveWidget={saveWidget}
        sectionType="Performance Graph"
        isDisabled={isDisabled}
        close={close}
      />
    </>
  );
};

export default TargetProgressSlideoutWizard;
