/* eslint-disable no-undef */
import { useContext, useEffect } from 'react';

import AccountContext from '../../../contexts/AccountContext';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import DashboardsContext from '../../../contexts/DashboardsContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import useHasAccess from 'hooks/useHasAccess';
import { useLocation } from 'react-router-dom';

const IntercomSetupContainer = () => {
  const { carrierName, status } = useContext(AccountContext);
  const currentUser = useContext(CurrentUserContext);
  const { selectedAccount } = useContext(AccountPickerContext);
  const { allDashboards, isLoading: isLoadingDashboards } =
    useContext(DashboardsContext);

  const location = useLocation();
  const hasAccess = useHasAccess();
  const assignedDashboards = allDashboards.filter((d) =>
    hasAccess({
      access: d.access,
      resource: d,
    }),
  );

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (currentUser) {
      // @ts-ignore
      window.Intercom('boot', {
        app_id: 'zxq8jxuk',
        created_at: currentUser.createdOn
          ? new Date(currentUser.createdOn).getTime()
          : undefined,
        name: currentUser.displayName, // Full name
        email: currentUser.email,
        userEmail: currentUser.email,
        isAdmin: currentUser.isAdmin,
        isSuperAdmin: currentUser.isSuperAdmin,
        isFleetOpsStaff: currentUser.isFleetOpsStaff,
        isWallBoardUser: currentUser.isWallboardUser,
        dashboardAssigned: isLoadingDashboards
          ? undefined
          : assignedDashboards.length,
        isAccountOnBoarded: status === 'active',
        company: selectedAccount
          ? {
              company_id: selectedAccount.accountId,
              name: selectedAccount.accountName,
            }
          : undefined,
      });
    }
  }, [
    selectedAccount,
    carrierName,
    currentUser,
    isLoadingDashboards,
    assignedDashboards.length,
    status,
  ]);

  useEffect(() => {
    if (!currentUser || process.env.NODE_ENV === 'test') {
      return;
    }

    // @ts-ignore
    window.Intercom('update', {
      last_page_load: new Date().getTime(),
      email: currentUser.email,
    });
  }, [currentUser, location.pathname]);

  return null;
};

export default IntercomSetupContainer;
