import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PeriodsContext from 'contexts/PeriodsContext';
import useQuickFilters from '../../../hooks/useQuickFilters';
import useDateScope from '../../../hooks/useDateScope';
import toFilterInput from '../../../toFilterInput';
import { PERIOD_QUERY_PARAM } from '../providers/SelectedEntityProvider';
import useQueryParams from 'hooks/useQueryParams';
import { platesToFilterInput } from 'screens/GoalShow/useGoalData';
import EntityDetailsContext from '../../../screens/EntityDetailsShow/EntityDetailsContext';
import PopupContext from '../../../contexts/PopupContext';
import AccountContext from '../../../contexts/AccountContext';

const useSelectInitialPeriod = () => {
  const urlParams = useQueryParams();

  const queriedStartDate = urlParams.has(PERIOD_QUERY_PARAM)
    ? (urlParams.get(PERIOD_QUERY_PARAM) as string)
    : undefined;

  return queriedStartDate;
};

/**
 * A board should be filtered by the selected entity when it is not in a popup
 * In a popup - we will use the entity filter if the source dashboard gadget did
 */
const useEntityFilter = (board: PerformanceBoardTypes.Board) => {
  const { isDemo6 } = useContext(AccountContext);
  const entityDetailsContext = useContext(EntityDetailsContext);
  const { isOpen: isPopupBoard } = useContext(PopupContext);
  const getEntityFilter = useCallback((): FilterInput | undefined => {
    if (!entityDetailsContext) {
      return undefined;
    }

    if (isPopupBoard) {
      return undefined;
    }

    if (isDemo6) {
      if (
        board.dataType === 'performanceDriver13Weeks' ||
        board.dataType === 'performanceDriverWeeks'
      ) {
        return undefined;
      }
    }

    return entityDetailsContext.entityFilter;
  }, [board.dataType, entityDetailsContext, isDemo6, isPopupBoard]);
  const [entityFilter, setEntityFilter] = useState<FilterInput | undefined>(
    () => getEntityFilter(),
  );

  useEffect(() => {
    setEntityFilter(getEntityFilter());
  }, [getEntityFilter]);

  return entityFilter;
};

// There is a lot of noise here.
// We will refactor / tidy when taking on Boards v3 project
const useFilteringState = ({
  board,
  popupFilters,
  scopeFilters,
}: {
  board: PerformanceBoardTypes.Board;
  popupFilters?: FilterPlate[];
  scopeFilters?: FilterPlate[];
}) => {
  const [dataTypes] = useState([board.dataType]);
  const initialPeriod = useSelectInitialPeriod();
  const { setSelectedPeriod, periods, selectedPeriod } =
    useContext(PeriodsContext);
  const navigate = useNavigate();
  const urlParams = useQueryParams();
  const entityFilter = useEntityFilter(board);
  const dateRange = useDateScope({});

  const quickFilters = useQuickFilters(
    `${board.dataType} - board: ${board.id}`,
  );
  const [variableDrillDowns] = useState<VariableDrillDownType[]>([]);
  const [drillDowns, setDrillDowns] = useState<FilterPlate[]>(
    () => popupFilters || window.emptyArray,
  );
  const [selectedQuickFilters, setSelectedQuickFilters] = useState<
    SavedFilter[]
  >([]);
  const [netQuickFilters, setNetQuickFilters] = useState<FilterInput[]>([]);

  const selectInitialPeriod = useCallback(
    (newValue: string) => {
      const queriedPeriod = periods.find(
        (period) => period.startDate === newValue,
      );
      if (queriedPeriod) {
        setSelectedPeriod(queriedPeriod);
      }
    },
    [periods, setSelectedPeriod],
  );

  useEffect(() => {
    if (initialPeriod && selectedPeriod) {
      selectInitialPeriod(initialPeriod);
      urlParams.delete(PERIOD_QUERY_PARAM);
      navigate({ search: urlParams.toString() });
    }
  }, [initialPeriod, navigate, selectInitialPeriod, selectedPeriod, urlParams]);
  useEffect(() => {
    const filters: FilterInput[] = [];
    filters.push(
      ...selectedQuickFilters.map((qf) =>
        toFilterInput(qf, variableDrillDowns),
      ),
    );
    if (board.filterInput) {
      filters.push(board.filterInput);
    }
    if (scopeFilters) {
      filters.push(platesToFilterInput(scopeFilters));
    }
    if (entityFilter) {
      filters.push(entityFilter);
    }
    setNetQuickFilters(filters);
  }, [
    board.filterInput,
    entityFilter,
    scopeFilters,
    selectedQuickFilters,
    variableDrillDowns,
  ]);

  return {
    dataTypes,
    dateRange,
    quickFilters,
    drillDowns,
    setDrillDowns,
    selectedQuickFilters,
    setSelectedQuickFilters,
    netQuickFilters,
  };
};

export default useFilteringState;
