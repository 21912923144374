import useQueryParams from './useQueryParams';
import { useState } from 'react';

const useIsSimpleViewDemo = () => {
  const urlParams = useQueryParams();
  const [isSimpleViewDemo] = useState<boolean>(() => urlParams.has('d'));

  return isSimpleViewDemo;
};

export default useIsSimpleViewDemo;
