import React, { useCallback } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import Typography from 'kingpin/atoms/Typography';

import Loading from '../../Loading/Loading';
import Row from '../../Common/Row';
import PerformanceChart from './Chart';
import Card from '../../Common/Card/index';
import formatDateLabel from '../../V5Gadget/formatDateLabel';
import { Badge, BadgeWrapper } from 'components/ReasonCodeCellRenderer';
import useValueFormatters from '../../../hooks/useValueFormatters';
import { HEADER_SPAN_CSS, SPAN_CSS } from '../../Grid/StyleOverrides';

const List = styled.div`
  max-height: 150px;
  overflow-y: scroll;
`;

const Col = styled.div<{
  width: number;
  rightAlign: boolean;
  isHeader?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => props.width}px;
  margin-right: 4px;
  align-items: ${(props) => (props.rightAlign ? 'flex-end' : 'flex-start')};
  text-align: ${(props) => (props.rightAlign ? 'right' : 'left')};
  overflow: hidden;

  svg {
    fill: white;
  }

  span {
    ${(props) => (props.isHeader ? HEADER_SPAN_CSS : SPAN_CSS)}
  }
`;

// +1 as we are pushing their start of week backwards
export const getDateLabel = (
  date: string,
  cadence?: 'day' | 'week' | 'month',
) => {
  if (cadence === 'day' || cadence === 'month') {
    return formatDateLabel(date, cadence);
  }
  const dt = DateTime.fromISO(date, { zone: 'utc' }).toLocal();
  return `${dt.weekNumber} (${formatDateLabel(date, 'week')})`;
};

const Performance = ({
  title,
  data,
  isLoading,
  cadenceLabel,
  config,
  dataType,
  cadence,
  isPerformanceBoard,
  homeViz,
}: {
  title: string;
  data: MetricsResponse;
  isLoading: boolean;
  cadenceLabel: string;
  config: TargetVisConfig;
  dataType: string;
  cadence?: 'day' | 'week' | 'month';
  isPerformanceBoard?: boolean;
  homeViz?: boolean;
}) => {
  const { formatField } = useValueFormatters();

  const getColWidth = useCallback(
    (isWideCol?: boolean) => {
      if (isWideCol) {
        return 100;
      }

      if (isPerformanceBoard) {
        return config.showReasonCode ? 55 : 74;
      }
      return config.showReasonCode ? 65 : 86;
    },
    [config.showReasonCode, isPerformanceBoard],
  );

  if (isLoading) {
    return (
      <div style={{ marginBottom: 24 }}>
        <Typography.Header type="H5">{title}</Typography.Header>
        <Loading isSmall />
      </div>
    );
  } else if (data.length === 0) {
    return (
      <div style={{ marginTop: 8 }}>
        <Typography.Body type="Label">
          There is no data for the chosen fields
        </Typography.Body>
      </div>
    );
  } else {
    return (
      <div style={{ marginBottom: homeViz ? '24px' : 0 }}>
        {!homeViz && <Typography.Header type="H5">{title}</Typography.Header>}
        <PerformanceChart data={data} config={config} cadence={cadence} />
        <Card style={{ overflow: 'hidden' }}>
          <div
            style={{
              padding: isPerformanceBoard ? '8px' : '8px 4px',
              backgroundColor: 'white',
            }}
          >
            <Row
              style={{
                borderBottom: `1px solid #E6E6EA`,
                color: '#5c5c5c',
                width: isPerformanceBoard ? 338 : 370,
                height: 34,
              }}
              centerAlign
            >
              <Col width={getColWidth(true)} rightAlign={false} isHeader>
                <span>{cadenceLabel}</span>
              </Col>
              <Col width={getColWidth()} rightAlign isHeader>
                <span>Target</span>
              </Col>
              <Col width={getColWidth()} rightAlign isHeader>
                <span>Actual</span>
              </Col>
              <Col width={getColWidth()} rightAlign isHeader>
                <span>Difference</span>
              </Col>
              {config.showReasonCode && (
                <Col width={getColWidth()} rightAlign isHeader>
                  <span>Reason Code</span>
                </Col>
              )}
            </Row>
            <List>
              {data.map((row) => (
                <Row
                  key={Math.random()}
                  style={{ height: 34, width: isPerformanceBoard ? 338 : 370 }}
                  centerAlign
                >
                  <Col width={getColWidth(true)} rightAlign={false}>
                    <span>
                      {getDateLabel(
                        row[config.dateKey ? config.dateKey : 'date'],
                        cadence,
                      )}
                    </span>
                  </Col>
                  <Col width={getColWidth()} rightAlign>
                    <span>
                      {formatField({
                        field: config.cadenceTargetField,
                        dataset: dataType,
                        value: row[config.cadenceTargetField],
                      })}
                    </span>
                  </Col>
                  <Col width={getColWidth()} rightAlign>
                    <span>
                      {formatField({
                        field: config.cadenceTotalField,
                        dataset: dataType,
                        value: row[config.cadenceTotalField],
                      })}
                    </span>
                  </Col>
                  <Col width={getColWidth()} rightAlign>
                    <span>
                      {formatField({
                        field: config.cadenceDifferenceField,
                        dataset: dataType,
                        value: row[config.cadenceDifferenceField],
                      })}
                    </span>
                  </Col>
                  {config.showReasonCode && (
                    <Col width={getColWidth()} rightAlign>
                      {row['reasonCode'] && (
                        <BadgeWrapper>
                          <Badge>
                            <span>
                              {Array.isArray(row['reasonCode'])
                                ? row['reasonCode'].join(', ')
                                : row['reasonCode']}
                            </span>
                          </Badge>
                        </BadgeWrapper>
                      )}
                      {!row['reasonCode'] && <span>-</span>}
                    </Col>
                  )}
                </Row>
              ))}
            </List>
          </div>
        </Card>
      </div>
    );
  }
};

export default Performance;
