import { useCallback, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import AuthenticationContext from '../../contexts/AuthenticationContext';
import isDefined from '../../isDefined';
import USER_ROLES from '../../roles';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import { AUTH_ERROR_MSG_KEY } from '../../screens/Login/ErrorBanner';
import STORE from '../../store';

const useUserAccounts = () => {
  const { uid, isSuperAdmin, isWallboardUser, wallBoardAccountId } = useContext(
    AuthenticationContext,
  );
  const { isFleetOpsStaff } = useContext(CurrentUserContext);
  const [accounts, setAccounts] = useState<FleetOps.UserAccountAccess[]>([]);
  const [quoteAccessAccounts, setQuoteAccessAccounts] = useState<
    FleetOps.UserAccountAccess[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getAccountInfo = useCallback(async (accountId: string) => {
    const doc = await STORE.getAccountRef({ accountId }).get();
    if (doc.exists) {
      const data = doc.data();
      if (data) {
        if (data.status === 'active' || data.status === 'pending') {
          return {
            accountName: data.carrier,
            isTemplateAccount: data.isTemplateAccount,
            isDemoAccount: !!data.isDemoAccount,
          };
        }
      }
    }
  }, []);

  const getSuperAdminAccounts = useCallback(async () => {
    const db = firebase.firestore();
    const allAccounts = await (async () => {
      const docs = await db.collection('accounts').get();
      const accounts = [] as {
        id: string;
        name: string;
        isTemplateAccount: boolean;
        isDemoAccount: boolean;
      }[];
      docs.forEach((doc: any) => {
        const data = doc.data() as FleetOps.Account;
        if (data.status === 'active' || data.status === 'pending') {
          accounts.push({
            id: doc.id,
            name: data.carrier,
            isTemplateAccount: data.isTemplateAccount,
            isDemoAccount: !!data.isDemoAccount,
          });
        }
      });

      return accounts;
    })();

    const newAccounts = [] as FleetOps.UserAccountAccess[];
    allAccounts.forEach((a) => {
      newAccounts.push({
        accountId: a.id,
        accountName: a.name,
        isTemplateAccount: a.isTemplateAccount,
        isDemoAccount: a.isDemoAccount,
        roles: ['fleetops.roles.superadmin'],
        permissions: [],
      });
    });

    return newAccounts;
  }, []);

  const getQuoteAccessAccounts = useCallback(async () => {
    const db = firebase.firestore();
    const allAccounts = await (async () => {
      const docs = await db.collection('accounts').get();
      const accounts = [] as { id: string; name: string }[];
      docs.forEach((doc: any) => {
        const data = doc.data();
        if (data.status === 'active' || data.status === 'pending') {
          accounts.push({
            id: doc.id,
            name: data.carrier,
          });
        }
      });

      return accounts;
    })();

    const newAccounts = [] as FleetOps.UserAccountAccess[];
    allAccounts.forEach((a) => {
      newAccounts.push({
        accountId: a.id,
        accountName: a.name,
        isTemplateAccount: false,
        isDemoAccount: false,
        roles: [USER_ROLES.FLEETOPS_SALES],
        permissions: [],
      });
    });

    return newAccounts;
  }, []);

  const getWallboardAccounts = useCallback(async () => {
    if (!wallBoardAccountId) {
      return undefined;
    }

    const doc = await STORE.getAccountRef({
      accountId: wallBoardAccountId,
    }).get();

    const account = doc.data();
    if (!account) {
      return;
    }

    return [
      {
        accountId: doc.id,
        accountName: account.carrier,
        isTemplateAccount: account.isTemplateAccount,
        isDemoAccount: !!account.isDemoAccount,
        roles: [],
        permissions: [],
      },
    ];
  }, [wallBoardAccountId]);

  useEffect(() => {
    if (!isFleetOpsStaff) {
      return;
    }
    getQuoteAccessAccounts().then((newAccounts) => {
      setQuoteAccessAccounts(newAccounts);
    });
  }, [getQuoteAccessAccounts, isFleetOpsStaff]);

  const refreshUserAccounts = useCallback(() => {
    if (isSuperAdmin) {
      getSuperAdminAccounts().then((newAccounts) => {
        setAccounts(newAccounts);
        setIsLoading(false);
      });
      return;
    }
    if (isWallboardUser) {
      getWallboardAccounts().then((newAccounts) => {
        if (newAccounts) {
          setAccounts(newAccounts);
        } else {
          setAccounts([]);
        }
        setIsLoading(false);
      });

      return;
    }
    const db = firebase.firestore();
    db.collection('userProfiles')
      .doc(uid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const profile = doc.data() as {
            accounts: {
              [accountId: string]: {
                roles: FleetOps.Role[];
                permissions: FleetOps.Permission[];
              } | null;
            };
          };
          const promises = Object.entries(profile.accounts).map(
            async ([key, access]) => {
              if (access === null) {
                return undefined;
              }
              const info = await getAccountInfo(key);
              if (!info) {
                return undefined;
              }
              const { accountName, isTemplateAccount } = info;

              return {
                accountId: key,
                accountName,
                isTemplateAccount,
                isDemoAccount: info.isDemoAccount,
                roles: access.roles,
                permissions: access.permissions,
              };
            },
          );
          Promise.all(promises).then((accountInfo) => {
            setAccounts(accountInfo.filter(isDefined));
            setIsLoading(false);
          });
        } else {
          window.localStorage.setItem(
            AUTH_ERROR_MSG_KEY,
            'You do not have access to FleetOps. Contact your IT admin',
          );
          firebase.auth().signOut();
        }
      });
  }, [
    getAccountInfo,
    getSuperAdminAccounts,
    getWallboardAccounts,
    isSuperAdmin,
    isWallboardUser,
    uid,
  ]);

  useEffect(() => {
    refreshUserAccounts();
  }, [refreshUserAccounts]);

  return { isLoading, accounts, quoteAccessAccounts, refreshUserAccounts };
};

export default useUserAccounts;
