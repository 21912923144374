import React, { useCallback, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import NavItem from './NavItem';
import AppRoutes from '../../navigation/appRoutes';
import PERMISSIONS from '../../permissionDefinitions';
import PermissionGates from 'components/PermissionGates';
import FeatureGate, { FEATURE_GATES } from '../../components/FeatureGate';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import FleetOpsStaffOnly from '../../components/Common/FleetOpsStaffOnly';
import AccountContext from '../../contexts/AccountContext';
import appRoutes from '../../navigation/appRoutes';
import NAVIGATION from './constants';
import NavSidebarProvider from '../../contextProviders/NavSideBarProvider';
import Colors2 from '../../theme/Colors2';
import PaceSettingsNavItem from './PaceSettingsNavItem';

const SettingsSideNavDiv = styled.div`
  width: 260px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  border-right: 1px solid ${Colors2.Border};
  background: #fff;
`;

const TitleDiv = styled.div`
  height: ${NAVIGATION.TOP_HEIGHT}px;
  padding: 0px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${Colors2.Border};

  span {
    color: #1e1a26;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 185.714% */
    letter-spacing: -0.042px;
  }
`;

const ItemsDiv = styled.div`
  padding: 16px 8px;
`;

const SettingsSideNav = () => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { id } = useContext(AccountContext);
  const isCommissionsAccount = id === 'fopsgfxARfMuprvVGPvS';

  const onSsoClicked = useCallback(() => {
    trackEvent('Settings - Clicked SSO Configurations');
  }, [trackEvent]);

  const onConnectorsClicked = useCallback(() => {
    trackEvent('Settings - Clicked Connectors');
  }, [trackEvent]);

  return (
    <NavSidebarProvider>
      <SettingsSideNavDiv>
        <TitleDiv>
          <span>Settings</span>
        </TitleDiv>
        <ItemsDiv>
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.USER_MANAGEMENT.VIEW_USER}
          >
            <NavItem
              to={AppRoutes.loggedIn.allUsersSettings}
              icon={'person-search'}
              label="Users"
            />
          </PermissionGates.Has>
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.DATA_MANAGEMENT.SLAS}
          >
            <NavItem
              to={AppRoutes.loggedIn.allTargetCategories}
              label="SLAs"
              icon={'navigation-metrics'}
            />
          </PermissionGates.Has>
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.BILLING.VIEW_BILLING}
          >
            <NavItem
              to={AppRoutes.loggedIn.billingSettings}
              icon={'billing-card'}
              label="Billing"
            />
          </PermissionGates.Has>
          <PermissionGates.Has
            requiredPermission={
              PERMISSIONS.DATA_MANAGEMENT.INTEGRATIONS_CONFIG_VIEW
            }
          >
            <NavItem
              to={AppRoutes.loggedIn.integrationsSettings}
              icon={'library-add'}
              label="Connectors"
              onClick={onConnectorsClicked}
            />
          </PermissionGates.Has>
          <FeatureGate featureName={FEATURE_GATES.MICROSOFT_SSO}>
            <PermissionGates.Has
              requiredPermission={PERMISSIONS.USER_MANAGEMENT.CONFIGURE_SSO}
            >
              <NavItem
                to={AppRoutes.loggedIn.ssoConfigurationsSettings}
                icon={'admin-panel-settings'}
                label="SSO Config"
                onClick={onSsoClicked}
              />
            </PermissionGates.Has>
          </FeatureGate>
          <PaceSettingsNavItem />
          <FleetOpsStaffOnly>
            <NavItem
              to={AppRoutes.loggedIn.advancedSettings}
              icon={'settings-empty'}
              label="Advanced"
            />
          </FleetOpsStaffOnly>
          <FleetOpsStaffOnly>
            <NavItem
              to={AppRoutes.loggedIn.copyContent}
              icon="copy"
              label="Copy Content"
            />
          </FleetOpsStaffOnly>
          <FleetOpsStaffOnly>
            <NavItem
              to={AppRoutes.loggedIn.copyContentHistory}
              icon="copy"
              label="Copy Content History"
            />
          </FleetOpsStaffOnly>
          {isCommissionsAccount && (
            <PermissionGates.Has
              requiredPermission={PERMISSIONS.INTERNAL_USE.MODIFY_COMMISSIONS}
            >
              <NavItem
                to={appRoutes.loggedIn.commissions}
                icon={'settings-empty'}
                label="Commissions"
              />
            </PermissionGates.Has>
          )}
        </ItemsDiv>
      </SettingsSideNavDiv>
      <Outlet />
    </NavSidebarProvider>
  );
};

export default SettingsSideNav;
