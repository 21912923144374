import { useContext, useEffect, useState } from 'react';
import CurrentUserContext from '../contexts/CurrentUserContext';
import AccountPickerContext from '../contexts/AccountPickerContext';
import DashboardsContext from '../contexts/DashboardsContext';
import ReportsContext from '../contexts/ReportsContext';
import WallboardsContext from '../contexts/WallboardsContext';
import ScorecardsContext from '../contexts/ScorecardsContext';
import RolesContext from '../contexts/RolesContext';
import ImpersonatorContext from 'contexts/ImpersonatorContext';
import useHasAccess from './useHasAccess';
import WorkSpacesContext from '../contexts/WorkSpacesContext';

const useRecents = () => {
  const { currentPermissions } = useContext(RolesContext);
  const [recents, setRecents] = useState<ContentView[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { availableReports } = useContext(ReportsContext);
  const { allDashboards } = useContext(DashboardsContext);
  const { allWorkSpaces } = useContext(WorkSpacesContext);
  const hasAccess = useHasAccess();
  const { wallBoards } = useContext(WallboardsContext);
  const currentUser = useContext(CurrentUserContext);
  const { accountRef } = useContext(AccountPickerContext);
  const { scorecards } = useContext(ScorecardsContext);
  const { impersonatorId } = useContext(ImpersonatorContext);

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'test' ||
      !currentUser ||
      currentUser.isWallboardUser
    ) {
      return () => {};
    }
    let isActive = true;
    setIsLoading(true);
    const listener = accountRef
      .collection('users')
      .doc(impersonatorId || currentUser.id)
      .collection('navigation')
      .doc('recents')
      .onSnapshot((snapshot) => {
        if (!isActive) {
          return;
        }
        if (snapshot.exists) {
          const data = snapshot.data();
          if (data) {
            const baseRecents = data.recents as ContentView[];
            const mappedRecents = baseRecents.map((r) => ({
              recent: r,
              resource: (() => {
                if (r.type === 'report') {
                  return availableReports.find(
                    (report) => report.id === r.typeId,
                  );
                } else if (r.type === 'dashboard') {
                  return allDashboards.find((d) => d.id === r.typeId);
                } else if (r.type === 'workspace' || r.type === 'targetsApp') {
                  return allWorkSpaces.find((ws) => ws.id === r.typeId);
                } else if (r.type === 'wallboard') {
                  return wallBoards.find((w) => w.id === r.typeId);
                } else if (r.type === 'scorecard') {
                  return scorecards.find((w) => w.id === r.typeId);
                } else {
                  return undefined;
                }
              })(),
            }));
            const visibleRecents = mappedRecents
              .filter((r) => {
                if (!r.resource) {
                  return false;
                }

                if (!r.resource.access) {
                  return true;
                }

                if (r.recent.type === 'wallboard') {
                  return true;
                }

                return hasAccess({
                  resource: r.resource,
                  access: r.resource.access,
                });
              })
              .map((r) => r.recent);
            setRecents(visibleRecents);
          } else {
            setRecents([]);
          }
        } else {
          setRecents([]);
        }
        setIsLoading(false);
      });
    return () => {
      isActive = false;
      listener();
    };
  }, [
    currentPermissions,
    accountRef,
    allWorkSpaces,
    currentUser,
    allDashboards,
    availableReports,
    wallBoards,
    scorecards,
    hasAccess,
    impersonatorId,
  ]);

  return {
    recents,
    isLoading,
  };
};

export default useRecents;
