import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import ReportDrillDownsContext from '../contexts/ReportDrillDownsContext';
import buildFilterInput from '../utils/buildFilterInput';
import GridContext from '../contexts/GridContext';
import mergeFilterInputs from '../mergeFilterInputs';
import ReportDrillDownContext from '../contexts/ReportDrillDownContext';
import CardContext from '../contexts/CardContext';
import visTypeCheckers from '../types/visTypeCheckers';
import EntityDetailsContext from '../screens/EntityDetailsShow/EntityDetailsContext';
import DashboardGadgetContext from '../contexts/DashboardGadgetContext';
import PopupContext from '../contexts/PopupContext';

export const usePopupEntityFilter = () => {
  const entityDetails = useContext(EntityDetailsContext);

  const {
    isOpen: isPopupOpen,
    selectedReport: popupReport,
    selectedBoard: popupBoard,
    dashboardGadget: popupFromGadget,
  } = useContext(PopupContext);

  const getPopupEntityFilter = useCallback(() => {
    if (entityDetails && entityDetails.entityFilter) {
      if (
        (popupReport || popupBoard) &&
        isPopupOpen &&
        popupFromGadget &&
        popupFromGadget.isEntityFilterEnabled
      ) {
        // If there is a popup which was triggered by a gadget with the
        // entity filter enabled
        return entityDetails.entityFilter;
      }
    }

    return undefined;
  }, [entityDetails, isPopupOpen, popupBoard, popupFromGadget, popupReport]);

  const [popupEntityFilter, setPopupEntityFilter] = useState<
    FilterInput | undefined
  >(getPopupEntityFilter());

  useEffect(() => {
    setPopupEntityFilter(getPopupEntityFilter());
  }, [getPopupEntityFilter]);

  return popupEntityFilter;
};

const useFilterInput = (field?: string, dataType?: string[]): FilterInput => {
  const entityDetails = useContext(EntityDetailsContext);
  const { netDrillDowns, netScope } = useContext(ReportDrillDownsContext);
  const { metricFiltering } = useContext(GridContext);
  const { chartDefinition } = useContext(CardContext);
  const { isOpen: isDrillDownOpen } = useContext(ReportDrillDownContext);
  const { dashboardGadget } = useContext(DashboardGadgetContext);
  const popupEntityFilter = usePopupEntityFilter();

  const getChartDefDimensionField = useCallback(() => {
    if (!chartDefinition) {
      return undefined;
    }

    if (visTypeCheckers.isV5ChartDef(chartDefinition)) {
      return chartDefinition.dimensionA && chartDefinition.dimensionA.field;
    }

    if (visTypeCheckers.isGauge(chartDefinition)) {
      return chartDefinition.peerGroup;
    }

    if (visTypeCheckers.isRankingMatrix(chartDefinition)) {
      return chartDefinition.groupByField;
    }

    if (visTypeCheckers.isSingleMetricDateMatrix(chartDefinition)) {
      return chartDefinition.groupByField;
    }

    return undefined;
  }, [chartDefinition]);

  const getIsIgnoringLastDrillDown = useCallback(() => {
    // Ignore the last drill-down for a dashboard/report gadget
    // if it is filtering against how the data is grouped
    if (netDrillDowns.length === 0 || chartDefinition === undefined) {
      return false;
    }

    const lastDrillField = netDrillDowns[netDrillDowns.length - 1].field;
    const dimensionField = getChartDefDimensionField();

    if (
      !!lastDrillField &&
      !!dimensionField &&
      lastDrillField === dimensionField
    ) {
      return true;
    }
  }, [chartDefinition, getChartDefDimensionField, netDrillDowns]);

  const getScopes = useCallback(() => {
    if (isDrillDownOpen) {
      return [];
    } else {
      return netScope;
    }
  }, [isDrillDownOpen, netScope]);
  const getDrillDowns = useCallback(() => {
    if (getIsIgnoringLastDrillDown()) {
      return netDrillDowns.slice(0, netDrillDowns.length - 1);
    } else if (isDrillDownOpen) {
      return netDrillDowns.filter((d) => d.field !== field);
    } else {
      return netDrillDowns;
    }
  }, [isDrillDownOpen, field, getIsIgnoringLastDrillDown, netDrillDowns]);
  const getFilterInput = useCallback(() => {
    const scopes = getScopes();
    const drillDowns = getDrillDowns();
    const fi = buildFilterInput({
      scopes,
      drillDowns,
      dataType,
    });

    const filtersToMerge: FilterInput[] = [];

    if (metricFiltering) {
      filtersToMerge.push(metricFiltering);
    }

    if (
      entityDetails &&
      entityDetails.entityFilter &&
      dashboardGadget &&
      dashboardGadget.isEntityFilterEnabled
    ) {
      filtersToMerge.push(entityDetails.entityFilter);
    }
    if (popupEntityFilter) {
      // If there is a popup which was triggered by a gadget with the
      // entity filter enabled
      filtersToMerge.push(popupEntityFilter);
    }

    if (filtersToMerge.length > 0) {
      return filtersToMerge.reduce(
        (a, b) => {
          return mergeFilterInputs(a, b);
        },
        { ...fi },
      );
    }

    return fi;
  }, [
    getScopes,
    getDrillDowns,
    dataType,
    metricFiltering,
    entityDetails,
    dashboardGadget,
    popupEntityFilter,
  ]);

  const [filterInput, setFilterInput] = useState<FilterInput>(() =>
    getFilterInput(),
  );

  useEffect(() => {
    setFilterInput((currentFilter) => {
      const newFilter = getFilterInput();
      if (_.isEqual(currentFilter, newFilter)) {
        return currentFilter;
      }

      return newFilter;
    });
  }, [getFilterInput]);

  return filterInput;
};

export default useFilterInput;
